const fetchNotifications: Object = async (
  perPage: number = 15,
  page: number = 1,
  query: string = ""
) => {
  let params: any = {
    per_page: perPage,
    page: page,
  };

  if (query.length >= 3) {
    params.query = query;
    params.page = 1;
  }

  const { data: notifications } = await dataFetch("/notifications", {
    params: params,
  });

  return notifications.value;
};

const fetchNotification: Object = async (id: Number) => {
  const { data: notification } = await dataFetch(`/notifications/${id}`);

  return notification.value;
};

const createNotification: any = async (body: Object) => {
  const { data, status, error } = await dataFetch("/notifications", {
    method: "post",
    body: body,
  });

  let errorMessages;
  if (status._value == "error") {
    errorMessages = error._object[error._key].data;
  }

  return { data, status, errorMessages };
};

const editNotification: any = async (id: Number, body: Object) => {
  const { data, status, error } = await dataFetch(`/notifications/${id}`, {
    method: "put",
    body: body,
  });

  let errorMessages;
  if (status._value == "error") {
    errorMessages = error._object[error._key].data;
  }

  return { data, status, errorMessages };
};

const deleteNotification: any = async (id: any) => {
  const { data, status, error } = await dataFetch(`/notifications/${id}`, {
    method: "delete",
  });

  return { data, status, error };
};

const fetchActiveNotification: Object = async () => {
  const { data: notification } = await dataFetch(`/notification`);

  return notification.value;
};

export {
  fetchNotifications,
  fetchNotification,
  createNotification,
  editNotification,
  deleteNotification,
  fetchActiveNotification
};
